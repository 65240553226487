import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Collapse,
	Container,
	Grid,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import InfoTitle from './InfoTitle';
import InfoWallet from './InfoWallet';
import CoverBasics from './CoverBasics';
import ArmaHeader from '~/components/Info/ArmaHeader';
import Faqs from '~/components/Homepage/Faqs';
import { faqArma, faqFreeCover } from '~/helpers/faqData';
import Footer from '~/components/Footer';
import Carousel from 'react-material-ui-carousel';
import api, { useGet } from '~/utils/api';
import { Policy } from '~/models/policy';
import { Link, useParams, useSearchParams, useLocation } from 'react-router-dom';
import DataCarousel from '~/components/DataCarousel';
import { pxToRem } from '~/utils/common';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import RedirectDialog from '~/components/RedirectDialog';
import WalletButtons from '~/components/WalletButtons';
import CoverSummary from '~/components/Info/CoverSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FrequentlyAskedQuestions from '~/components/Info/FrequentlyAskedQuestions';
import { FONT_DAZZLE } from '~/theme/typography';
import { flushSync } from 'react-dom';

const Info: React.FC<{}> = () => {
	const { data: policies } = useGet<{
		results: Policy[];
	}>(api.uni.policy);

	const { university } = useParams();

	// Get a specific query parameter

	const policy = policies?.results.find(
		(policy) => policy.name.toLowerCase() === university?.toLowerCase()
	);

	const [open, setOpen] = React.useState(false);
	const [coverInfoOpen, setCoverInfoOpen] = useState(false);
	const policyShortLink = policy?.name ? policy.name.toLowerCase() : 'policy';

	//Use query search params under ?policy=[policyNumber] to find the university you want.
	const [searchParams, setSearchParams] = useSearchParams();
	const [currentSlide, setCurrentSlide] = useState(0);
	const subject = `Policy Number: ${policy?.policyNumber}`;
	const body = `Hi, My policy number is ${
		policy?.policyNumber
	}\n\nTo download my policy documents, click here: ${policy?.wordingPDF || ''}`;

	useEffect(() => {
		flushSync(() => {
			if (location.hash) {
				const hash = location.hash.split('#')[1];
				const element = document.getElementById(hash);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' });
				}
			}
		});
	}, [location?.hash]);

	const isDownloadable = searchParams.get('download') === 'true';

	useEffect(() => {
		if (policy?.policyNumber) {
			setSearchParams((prevParams) => {
				prevParams.set('policyNumber', policy?.policyNumber ?? '');
				return prevParams;
			});
		}
		if (!isDownloadable) return;
		// Remove automaticaly downloading the policy PDF document as this will (by default) open the downloaded file in a
		// new tab.

		// if (policy) {
		// 	// fetch a blob of the PDF as the download attribute ignores files origiating from another site. Constructs an
		// 	// anchor link and clicks it. Default behaviour for most browsers is to automaticaly open PDFS so this may still
		// 	// look like this is opening in a new tab. But it will be saved locally.
		// 	fetch(policy.wordingPDF)
		// 		.then((response) => response.blob())
		// 		.then((blob) => {
		// 			const url = window.URL.createObjectURL(blob);
		// 			const a = document.createElement('a');
		// 			a.style.display = 'none';
		// 			a.href = url;
		// 			a.download = 'Group Possessions Policy Wording.pdf';
		// 			document.body.appendChild(a);
		// 			a.click();
		// 			window.URL.revokeObjectURL(url);
		// 			document.body.removeChild(a);
		// 		})
		// 		.catch(() => alert('An error occurred while downloading the file.'));
		// 	setSearchParams((prev) => {
		// 		prev.delete('download');
		// 		return prev;
		// 	});
		// }
	}, [policy, policies]);

	return (
		<Styles>
			<RedirectDialog
				setOpen={setOpen}
				newTab={true}
				link={'https://armakarma.insure/claims/'}
				open={open}
				handleClose={() => setOpen(false)}
			/>

			<Stack className="header gradient">
				<Box className="header-image">
					<img src="https://arma-karma.s3.eu-west-2.amazonaws.com/brooke-cagle-g1Kr4Ozfoac-unsplash+1.png" />
				</Box>
				<Container maxWidth="sm" disableGutters className="header-hero">
					<Stack direction="row" gap={2} className="mx-auto">
						<Typography variant="h1" fontWeight={400} className="hero-text">
							Welcome to
						</Typography>
					</Stack>
					<Stack direction="row" gap={2} className="mx-auto">
						<Typography
							variant="h1"
							fontWeight={700}
							lineHeight={1.2}
							className="hero-text hero-text-slanted"
						>
							The Hub
						</Typography>
					</Stack>
					<Stack className="mx-auto" mt={2}>
						<Typography variant="h3" fontWeight={400} className="hero-text">
							Your Policy Number:
						</Typography>
						<Typography
							variant="h3"
							fontWeight={700}
							color="secondary.main"
							className="hero-text"
						>
							{policy?.policyNumber}
						</Typography>
					</Stack>
				</Container>
			</Stack>
			<Container maxWidth={false} disableGutters className="download-bar">
				<Typography variant="h2" component="h4" className="download-bar-scale-text">
					<Box component="span" sx={{ color: 'secondary.darker' }}>
						3 simple steps{' '}
					</Box>{' '}
					for peace-of-mind
				</Typography>
			</Container>

			<Box className="info-content">
				<Container maxWidth="sm" sx={{ pt: 10 }}>
					<Stack direction="column" spacing={10}>
						<Box>
							<Stack direction="row" className="info-content-title">
								<Box className="info-content-title-number">
									<Typography variant="h1">1</Typography>
								</Box>

								<Typography
									className="info-content-title-text"
									variant="h2"
									fontWeight="bold"
								>
									Download your cover
								</Typography>
							</Stack>
							<Typography variant="body1">
								Have your peace of mind by downloading your cover or adding it to
								your digital wallet.
							</Typography>

							<Stack
								justifyContent="center"
								width="50%"
								direction="row"
								className="info-content-grid"
							>
								<Stack
									spacing={{ xs: 2, md: 8 }}
									direction="row"
									justifyContent="space-between"
									className="info-content-grid-stack"
								>
									<Button
										component="a"
										href={policy?.wordingPDF ?? ''}
										download="Group Possessions Policy Wording.pdf"
										target="_blank"
										className="info-content-grid-stack-download download btn"
										color="secondary"
									>
										<DownloadForOfflineIcon className="icon-large" />
									</Button>
									<Button
										component="a"
										href={`mailto:?subject=${encodeURIComponent(
											subject
										)}&body=${encodeURIComponent(body)}`}
										className="info-content-grid-stack-download email btn"
										color="secondary"
									>
										<MailOutlineIcon className="icon-large-mail" />
									</Button>

									<Button
										href={policy?.link ?? '#'}
										target="_blank"
										className="info-content-grid-stack-download make-claim btn"
										color="secondary"
									>
										Make a claim
									</Button>
								</Stack>
							</Stack>

							{policy && <WalletButtons policy={policy} />}
						</Box>

						<Box id="cover-details">
							<Stack direction="row" className="info-content-title">
								<Box className="info-content-title-number">
									<Typography variant="h1">2</Typography>
								</Box>

								<Typography
									className="info-content-title-text"
									variant="h2"
									fontWeight="bold"
								>
									Get to know your cover
								</Typography>
							</Stack>
							<Typography variant="body1">
								We’ve put together a small video to help answer any questions you
								may have, and highlight areas of the cover you may want to read in
								more detail.
							</Typography>

							<Stack
								justifyContent="center"
								alignItems="center"
								py={5}
								className="info-content-cover"
							>
								<iframe
									className="info-content-cover-video"
									src="https://player.vimeo.com/video/853545987?h=064cd07e31"
								></iframe>
							</Stack>

							<Stack
								alignItems="center"
								justifyContent="center"
								spacing={3}
								className="info-content-cover-info"
							>
								<Button
									onClick={() => setCoverInfoOpen(!coverInfoOpen)}
									endIcon={
										<Stack
											sx={{
												transform: coverInfoOpen
													? 'rotate(180deg)'
													: 'rotate(0deg)',
												transition: 'transform 0.3s ease-in-out',
												margin: 'auto',
											}}
											justifyContent="center"
											alignItems="center"
										>
											<ExpandMoreIcon />
										</Stack>
									}
									variant="text"
								>
									Cover Summary
								</Button>
								<Collapse sx={{ maxWidth: '100%' }} in={coverInfoOpen}>
									<CoverSummary summary={policy?.coverSummaryInfo} />
								</Collapse>
							</Stack>

							<Stack
								spacing={6}
								direction="column"
								alignItems="center"
								sx={{ mt: 6 }}
							>
								<Typography className="info-content-title-text" variant="h2">
									Frequently Asked Questions
								</Typography>
								<FrequentlyAskedQuestions
									faqs={faqFreeCover(policy?.disableFree ?? false)}
								/>
							</Stack>
						</Box>

						<Box id="info">
							<Stack direction="row" className="info-content-title">
								<Box className="info-content-title-number">
									<Typography variant="h1">3</Typography>
								</Box>

								<Typography
									className="info-content-title-text"
									variant="h2"
									fontWeight="bold"
								>
									Build on your cover
								</Typography>
							</Stack>
							<Typography variant="body1">
								Like many others, you may need cover for your stuff when you're out
								and about, whether it's coffee spilled on your Laptop or an AirPod
								down the drain. We've partnered with Arma Karma to give you the
								opportunity to build your own monthly insurance subscription for the
								items that matter most, starting from {policy?.pricing ?? '£1.99'} a
								month.
							</Typography>

							<Typography variant="body1" mt={2}>
								To find out more, click{' '}
								<Box
									component="span"
									sx={{
										fontWeight: 700,
									}}
								>
									Get Started
								</Box>
							</Typography>
						</Box>
					</Stack>
				</Container>
				<Stack gap={8}>
					<ArmaHeader />
					<Container disableGutters maxWidth="sm">
						<Typography variant="body1" sx={{ px: 3 }}>
							As a certified B-Corp, (think fair-trade for business) Arma Karma wants
							Insurance to have a larger, positive impact.
							<Box component="span" sx={{ fontWeight: 700 }}>
								{' '}
								25% of what they make from your subscription gets donated to a
								charity of your choice.
							</Box>
						</Typography>
					</Container>
					<Container maxWidth="sm">
						<Typography
							variant="h2"
							component="h3"
							sx={{ textAlign: 'center', textTransform: 'uppercase' }}
							mb={6}
							fontFamily="body2.fontFamily"
							color="primary.darker"
						>
							Arma karma FAQs
						</Typography>
						<FrequentlyAskedQuestions faqs={faqArma} />
					</Container>
				</Stack>

				<Box sx={{ my: 12 }}></Box>
			</Box>
			<Footer />
		</Styles>
	);
};

const Styles = styled.div`
	.mx-auto {
		${({ theme }) => theme.breakpoints.down('sm')} {
			margin: 0 auto;
		}
	}
	.download-bar {
		display: flex;
		background: ${({ theme }) => theme.palette.secondary.main};
		color: ${({ theme }) => theme.palette.common.white};
		text-align: center;
		padding: ${({ theme }) => theme.spacing(2, 0)};
		justify-content: center;
		position: relative;
		.download-bar-scale-text {
			${({ theme }) => theme.breakpoints.down('md')} {
				font-size: ${() => pxToRem(36)};
			}
			${({ theme }) => theme.breakpoints.down('sm')} {
				font-size: ${() => pxToRem(28)};
			}
			@media (max-width: 600px) {
				font-size: ${() => pxToRem(22)};
			}
		}
	}
	.header {
		position: relative;
		width: 100%;
		background: linear-gradient(to right, white 0%, white 43%, transparent 100%);
		${({ theme }) => theme.breakpoints.down('sm')} {
			background: linear-gradient(
				to top,
				white 0%,
				rgba(255, 255, 255, 0.5) 80%,
				transparent 100%
			);
		}
		overflow: hidden;
		max-height: max-content;
		height: 100%;
		padding: ${({ theme }) => theme.spacing(18, 2)};
		&-hero {
			${({ theme }) => theme.breakpoints.down('sm')} {
				text-align: center;
				align-items: center;
				display: flex;
				flex-direction: column;
			}
		}
		&-image {
			z-index: -1;
			margin-left: auto;
			position: absolute;
			width: 100%;
			margin-bottom: auto;
			top: 0;
			& > img {
				${({ theme }) => theme.breakpoints.up('md')} {
					width: 65%;
				}
				${({ theme }) => theme.breakpoints.down('sm')} {
					width: auto;
					height: auto;
				}
				object-fit: contain;
				height: auto;
				min-height: 100%;
				height: auto;
				object-position: 100% 100%;
				margin-left: auto;
				right: 0;
				position: absolute;
				top: 0;
			}
		}
	}

	.hero-text-slanted {
		transform: rotate(0deg);
		position: relative;
		color: ${({ theme }) => theme.palette.common.white};
		padding: ${({ theme }) => theme.spacing(0, 2)};
		&:after {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			background: #fff;
			top: 0;
			left: 0;
			z-index: -1;
			transform: rotate(-1deg);
			background: ${({ theme }) => theme.palette.secondary.main};
		}
	}

	.info-content {
		color: #002a44;
		height: 100%;
		background: rgb(255, 255, 255);

		&-cover-info {
			* {
				color: ${({ theme }) => theme.palette.secondary.darker};
			}
		}

		&-title {
			&-text {
				${({ theme }) => theme.breakpoints.down('md')} {
					font-size: 1.7rem;
				}
			}
			align-items: center;
			${({ theme }) => theme.breakpoints.up('md')} {
				margin-left: -60px;
			}
			&-number {
				width: 60px;
				font-size: ${pxToRem(80)};
				color: #0075bc;
			}
		}

		&-grid {
			padding-top: ${({ theme }) => theme.spacing(4)};
			min-height: 0;
			flex-shrink: initial;
			width: 100%;
			&-stack {
				min-height: 0;
				flex: 1;
				padding-bottom: ${({ theme }) => theme.spacing(8)};

				&-download {
					flex: 1;
					max-width: 200px;
					padding: ${({ theme }) => theme.spacing(1, 0)};

					&-arrow {
						margin: auto;
						font-size: ${({ theme }) => theme.spacing(6)};
					}

					&.make-claim {
						${({ theme }) => theme.breakpoints.only('xs')} {
							font-size: 12px;
						}
					}

					&.email,
					&.download {
						svg {
							width: 52px;
							height: 52px;
						}
					}
				}
			}
			margin-top: ${({ theme }) => theme.spacing(3)};
		}

		&-cover {
			&-video {
				background-color: ${({ theme }) => theme.palette.secondary.darker};
				border: 5px solid ${({ theme }) => theme.palette.secondary.darker};
				border-radius: 10px;
				bkit-box-shadow: 8px 8px 0px 1px ${({ theme }) => theme.palette.secondary.darker};
				-moz-box-shadow: 8px 8px 0px 1px ${({ theme }) => theme.palette.secondary.darker};
				box-shadow: 8px 8px 0px 1px ${({ theme }) => theme.palette.secondary.darker};
				aspect-ratio: 16/9.1111111111;
				width: 100%;
				${({ theme }) => theme.breakpoints.up('md')} {
					width: 90%;
				}
			}
		}
	}
`;

export default Info;
